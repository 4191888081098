.site_modal .modal-content {
    border-radius: 15px;
}

.modal-body {
    padding: 0 !important;
}

.modal-body hr {
    width: 95%;
    margin: 20px auto;
}

.review_header {
    display: contents;
}

.modal-body p {
    color: #000;
    font-weight: 500;
}

.review_header svg {
    font-size: 24px;
    color: #f48847;
    cursor: pointer;
}

.package_modal img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
}

.package_detail {
    padding: 15px;
}

.select_package {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background-color: #f48847;
    border: none;
    padding: 10px 0;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

@media (max-width: 767px) {
    .modal-header {
        padding: 5px;
    }

    .package_modal img {
        width: 100%;
        height: 120px;
        object-fit: cover;
        object-position: center;
    }
}
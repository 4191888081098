.home_banner {
  /* background-image: url("/public/images/home_banner2.png"); */
  /* background-size: cover; */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 180px 0;
  height: 92vh;
}

.home_banner h2 {
  text-align: center;
  color: #000;
  letter-spacing: 3px;
  font-size: 38px;
  font-weight: 700;
}

.search_box {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 0px 10px;

  box-shadow: 0 1px 2px rgb(0 0 0 / 0%), 0 4px 12px rgb(0 0 0 / 14%);
  border: 1px solid #DDDDDD
}

.search_box .row>div:nth-child(1),
.search_box .row>div:nth-child(2) {
  border-right: 1px solid #dedede;
}

.search_box input {
  width: 100%;
  height: 60px;
  padding-left: 10px;
  border: none;
}

.search_box input:focus-visible {
  border: none;
  outline: none;
}

.search_box button {
  background-color: #f48847;
  color: white;
  padding: 12px 30px;
  font-size: 12px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}

.scroll_btn {
  position: absolute;
  bottom: 20px;
  border: none;
  background-color: transparent;
  color: white;
  /* left: 50%; */
}

.manual_box h4{
  color: #fff;
  text-align: center;
  margin-top: 5px;
  font-size: 18px;
}

.addon_div span{
  margin: 7px 5px;
  font-size: 14px;
}


@media (max-width: 767px) {
  .search_box {
    padding: 10px 10px;
  }

  .home_banner {
    padding: 12px 0;
    min-height: 93vh;
    height: 100%;
    align-items: start;
  }

  .home_banner h2 {
    font-size: 32px;
  }

  .copyrights_container {
    flex-flow: wrap;
    justify-content: center;

  }

  .search_box input {
    width: 100%;
    height: 50px;
    padding-left: 10px;
    border: none;
    border-bottom: 1px solid #00000059;
    margin-bottom: 15px;
  }
}
.profile_picture {
    width: 130px;
    height: 130px;
    border-radius: 100%;
    object-fit: cover;
}

.profile_settings {
    background-color: #fff;
    z-index: 1;
    position: relative;
    box-shadow: 0px 57px 80px 0px rgb(100 149 191 / 35%);
    padding: 20px;
    margin: 30px 0;
    border-radius: 15px;
}

.profile_pic_input {
    font-size: 12px;
    font-weight: 500;
    margin: 10px 0 0px 0;
}

.profile_settings h4 {
    background-color: #111111;
    text-transform: uppercase;
    padding: 10px 10px;
    font-size: 19px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 15px;
}

.profile_settings button {
    border-radius: 15px !important;
    font-weight: bolder !important;
    background-color: #f48847 !important;
    padding: 10px 20px;
    margin-bottom: 15px;
    margin-top: 10px;
    border: 1px solid #f48847;
    font-size: 13px;
}

.profile_settings .form-label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
}
.navbar.navbar-expand-lg {
    padding: 10px 0;
}

.navbar-brand {
    font-family: 'FONTSPRING DEMO - Orpheus Pro Med', sans-serif;
    font-weight: 500;
    font-style: normal;
}

.navbar-expand-lg .navbar-nav {
    flex-direction: row;
    gap: 25px;
    align-items: center;
}

.navbar-nav>div {
    display: flex;
    align-items: flex-start;
}

.navbar-nav>div svg {
    color: #f48847;
    font-size: 22px;
    margin-right: 5px;
}

.navbar-nav>div p {
    color: #f48847;
    font-size: 10px;
    font-weight: 800;
}

.navbar-nav>div h6 {
    font-size: 14px;
    font-weight: 700;
}

.navbar-nav>div button {
    background-color: #f48847;
    color: white;
    padding: 10px 30px;
    font-size: 12px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
}

.dropdown-toggle.nav-link {
    color: #000 !important;
}

@media (max-width: 767px) {
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
        gap: 25px;
        align-items: center;
        flex-wrap: wrap;
        padding: 15px 0;
    }
}
.packages_banner {
  /* background-image: url("/public/images/packages-banner.png"); */
  /* background-size: cover; */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  /* height: 60vh; */
  /* width: 100vw; */
  /* max-width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 85px 0;
}

.packages_banner h2 {
  text-align: center;
  color: #000;
  letter-spacing: 3px;
  font-size: 40px;
  font-weight: 700;
}

.service_main {
  /* border-width: 1px; */
  /* border-color: rgb(225, 225, 225); */
  /* border-style: solid; */
  border-radius: 10px;
  /* background-color: rgb(255, 255, 255); */
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 20px;
  position: relative;
}

.saving {
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 30px 30px 0px rgba(181, 181, 181, 0.3);
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 5px 10px;
}

.saving h6 {
  color: #f48847;
  font-weight: 600;
  font-size: 14px;
}

.service_main>img {
  width: 100%;
  object-fit: cover;
  height: 260px;
}

.service_main>div h2 {
  font-size: 22px;
  font-weight: 700;
}

.service_main .service_info {
  font-size: 13px;
}

.benefits img {
  width: 16px;
  margin-right: 5px;
}

.benefits p {
  font-size: 10px;
  font-weight: 600;
}

.num_of_services {
  border-radius: 10px;
  background-color: #e2e2e294;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  height: 40px;
  padding: 0px 20px;
  cursor: pointer;
}

.num_of_services h6 {
  font-size: 15px;
  font-weight: 600;
  color: #808080d1;
}

.service_amount {
  border-radius: 10px;
  background-color: rgb(247, 247, 247);
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 5px;
  padding-left: 20px;
}

.service_amount h6 {
  font-size: 20px;
  font-weight: 800;
}

.service_amount button {
  background-color: #f48847;
  color: white;
  padding: 7px 30px;
  font-size: 18px;
  height: 100%;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}

.amount_container {
  border-width: 1px;
  border-color: rgb(225, 225, 225);
  border-style: solid;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 30px 40px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 15px;
}

.amount_container>div:first-child {
  padding-bottom: 50px;
}

.amount_container button {
  background-color: #f48847;
  color: white;
  padding: 7px 30px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  width: 100%;
}

.package_price {
  border-radius: 6px;
  background-color: rgb(247, 247, 247);
  color: #f48847;
  margin: 10px 0;
  width: fit-content;
  padding: 10px 25px;
  font-size: 17px;
  font-weight: 600;
}

.service_tabs {
  display: flex;
}

.service_tabs button {
  width: 100%;
  border: none;
  padding: 10px;
  font-weight: 500;
  margin-bottom: 25px;
  background-color: #fff !important;
  color: #000 !important;
  position: static !important;
}

.service_tabs button.active {
  background-color: #f48847 !important;
  color: #fff !important;
}

.amount_container h5 {
  margin-top: 10px;
  font-size: 17px;
  font-weight: 700;
}

.gallery_custom_slick_next {
  background-image: url("../../public/images/gallery_arrow_right.png") !important;
  width: 21px !important;
  background-repeat: no-repeat !important;
  background-size: 8px !important;
  background-color: black;
  height: 21px;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  justify-content: center;
  background-position: center;
  margin-left: 3px;
}

.gallery_custom_slick_prev {
  background-image: url("../../public/images/gallery_arrow_left.png") !important;
  width: 21px !important;
  background-repeat: no-repeat !important;
  background-size: 8px !important;
  background-color: black;
  height: 21px;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  justify-content: center;
  background-position: center;
  margin-left: 3px;
}

.gallery_custom_slick_next::before,
.gallery_custom_slick_prev::before {
  display: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: black;
}

.addon_div img {
  max-width: 100%;
  height: 125px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.addon_div h6 {
  font-size: 14px;
  font-weight: 600;
  margin: 7px 5px;
}

.addon_div p{
  color: rgb(244, 136, 71);
  margin: 0 5px;
  font-size: 15px;
}

.addon_div button {
  background-color: #f48847;
  border: none;
  display: flex;
  /* margin-left: auto; */
  margin-top: 10px;
  /* width: 40px; */
  color: #fff;
  font-size: 25px;
  justify-content: center;
}

.addon_div {
  border: 1px solid #80808038;
  padding: 0px;
  margin-bottom: 25px;
  border-radius: 10px;
  background-color: #fff;
  height: 100%;
  position: relative;
}

.select_package_btn {
  background-color: #f48847;
  color: white;
  padding: 0px 14px !important;
  font-size: 20px !important;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: static !important;
}

.location_modal {
  padding: 10px;
}

.location_modal input {
  border: none;
  border-bottom: 1px solid grey;
  width: 100%;
  margin-bottom: 40px;
  padding: 4px 0px;
}

.pac-container {
  z-index: 999999999999999999 !important;
}

.modal_property {
  background-color: #f48847;
  color: white;
  padding: 12px 30px;
  font-size: 12px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
}

.calender_main {
  height: 92vh;
  display: flex;
  align-items: center;
}

.scheduling {
  border: 1px solid #e1e1e1;
}

.schedule_shoot {
  padding: 20px;
  border-right: 1px solid #e1e1e1;
}

.schedule_shoot h4 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
}

.schedule_shoot p {
  font-size: 13px;
}

.schedule_shoot h6 {
  margin-top: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.schedule_shoot h5 {
  font-size: 15px;
}

.react-calendar {
  width: 670px !important;
  border: none !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  font-size: 35px !important;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.calendery{
  padding: 20px 0;
}

.calendery h4{
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
}

.react-calendar__tile--active {
  background: #f48847 !important;
  color: white !important;
}

.react-calendar__tile--now {
  background: #f3f3f3;
}

.calendery abbr[title]{
  text-decoration: none !important;
}

.select_time{
  padding: 20px 10px;
}

.select_time h4{
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
}

.select_time h6{
  font-size: 15px;
  font-weight: 600;
  margin-top: 15px;
}

.select_schedule{
  padding: 0px 20px;
}

.select_schedule div{
  text-align: center;
  border: 1px solid #80808059;
  padding: 5px 0;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.select_schedule div.active{
  background-color: #f48847;
}

.select_schedule div h6, .select_schedule div p{
  font-size: 14px;
}

.select_schedule div p{
  color: #f48847;
  font-weight: 600;
}

.select_schedule div.active h6, .select_schedule div.active p{
  color: #fff;
}

.checkout_div{
  text-align: end;
  margin-top: 30px;
}

.checkout_div button{
  background-color: #f48847;
  color: white;
  padding: 7px 50px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}

.checkout_page{
  border: 1px solid #e1e1e1;
  padding: 20px;
  border-radius: 10px;
}

form.coach_register.make_border {
  background-color: #fcfcfc;
  margin-top: 15px;
}

form.coach_register.make_border button{
  background-color: #f48847;
  color: white;
  padding: 7px 30px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  width: 100%;
}

.package_included img{
  width: 100%;
  height: 125px;
}


@media (max-width: 767px) {
  .num_of_services {
    border-radius: 10px;
    background-color: #e2e2e294;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    height: 39px;
    padding: 0px 10px;
    cursor: pointer;
  }

  .packages_banner {
    padding: 30px 0;
  }

  .packages_banner h2 {
    font-size: 25px;
  }

  .service_main>img {
    width: 100%;
    object-fit: cover;
    height: 135px;
  }

  .saving {
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 30px 30px 0px rgba(181, 181, 181, 0.3);
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 5px 10px;
  }

  .service_main>div h2 {
    font-size: 18px;
    font-weight: 700;
  }

  .service_tabs button {
    font-size: 12px !important;
  }

  .benefits h6 {
    font-size: 13px !important;
  }

  .benefits .col-md-3 {
    margin-bottom: 15px;
  }

  .addon_div h6 {
    font-size: 16px;
  }
}
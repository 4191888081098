.active~.page_responsive {
    width: calc(100% - 220px);
    left: 220px;
    transition: all 0.2s ease;
    overflow-x: hidden;
    border-top: 1px solid #00000014;
}

.page_responsive {
    position: absolute;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 1%;
    height: calc(92vh - 0px);
    background-color: #f3f8fe;
    left: 75px;
    transition: all 0.2s ease;
    overflow-x: hidden;
    border-top: 1px solid #00000014;
}

.page_responsive::-webkit-scrollbar {
    margin-top: 3px;
    width: 5px;
    cursor: pointer;
}

.page_responsive::-webkit-scrollbar-track {
    background: #e4e4e5;
}

.page_responsive::-webkit-scrollbar-thumb {
    background: #1a2d4b;
    cursor: pointer;
}

.main_head{
    font-weight: 700;
    margin-bottom: 25px;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: 'FONTSPRING DEMO - Orpheus Pro Med';
  src: url('/public/fonts/FONTSPRINGDEMO-OrpheusProMedRegular.woff2') format('woff2'),
    url('/public/fonts/FONTSPRINGDEMO-OrpheusProMedRegular.woff') format('woff'),
    url('/public/fonts/FONTSPRINGDEMO-OrpheusProMedRegular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px;
}

.web {
  display: block;
}

.mob {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 767px) {
  .web {
    display: none;
  }

  .mob {
    display: block;
  }
}
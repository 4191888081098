.create_package {
    text-align: end;
    margin-bottom: 20px;
}

.create_package button {
    background-color: #f48847;
    color: white;
    padding: 12px 30px;
    font-size: 12px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
}

.create_package_form {
    background-color: #fff;
    padding: 20px;
}

.create_package_form .form-label {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 600;
    color: #000;
}

.create_package_form .create_btn {
    text-align: end;
}

.create_package_form .create_btn button {
    background-color: #f48847;
    color: white;
    padding: 12px 30px;
    font-size: 12px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
}

.preview_img img {
    width: 100px;
}

.fileUploader .fileContainer {
    box-shadow: none;
    border: 1px solid #0000003b;
}

.gallery_preview img{
    width: 120px;
    height: 110px;
    object-fit: cover;
}

.gallery_preview {
    display: flex;
    position: relative;
    width: fit-content;
}

.gallery_preview svg{
    position: absolute;
    right: 0px;
    top: 1px;
    color: #000;
    font-size: 20px;
    cursor: pointer;
}

.fileContainer .uploadPictureContainer {
    width: 10% !important;
    margin: 1% !important;
    padding: 6px !important;
    background: #edf2f6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid #d0dbe4;
    position: relative;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
    width: 100px !important;
    height: 100px !important;
    object-fit: cover !important;
}

.fileContainer .deleteImage {
    position: absolute;
    top: -5px !important;
    right: -6px !important;
    color: #fff;
    background: #ff4081;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 15px !important;
    font-weight: bold;
    line-height: 23px !important;
    width: 20px !important;
    height: 20px !important; 
}

.service_main button{
    background-color: #f48847;
    color: white;
    padding: 2px 12px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.price_tag{
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    align-items: end;
}

.price_tag div{
    width: 100%;
}

.price_tag button{
    background-color: #f48847;
    color: white;
    padding: 12px 30px;
    font-size: 12px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    width: 300px;
}
.footer{
    background-color: #111111;
    width: 100vw;
    max-width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;
}
.footer h3{
    font-family: 'FONTSPRING DEMO - Orpheus Pro Med';
    font-weight: 500;
    font-style: normal;
}
.footer p{
    font-size: 14px;
}
.footer_info svg{
    font-size: 24px;
    color:#f48847
}
.copyrights_container{
    width: 100%;
    background-color: white;
    padding: 15px 20px;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.social_icon_box{
        border: 1px solid rgb(241, 242, 244);
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 10px 20px 0px rgba(0, 59, 109, 0.2);
        width: 42px;
        height: 42px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      
}
.social_icon_box > svg{
    font-size: 18px;
    color: #f48847;
}
.social_icon_box h5{
    font-weight: 700;
}
.coach_register h2 {
    color: #111111;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    text-transform: uppercase;
}

.coach_register .form-label {
    color: #000 !important;
    text-transform: capitalize;
    font-weight: 800;
    font-size: 14px;
    margin-bottom: 0;
}

.coach_register .btn {
    background-color: #f48847;
    color: white;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    margin-top: 15px;
}

.coach_register {
    padding: 20px;
    position: relative;
    text-align: left;
}

.coach_register label {
    font-size: 13px;
    font-weight: 600;
}

.coach_register label span {
    color: red;
}

.main_register_form {
    background-color: #fff;
    position: relative;
    margin-bottom: 30px;
    text-align: center;
}

.inner_register_form {
    padding: 40px 70px;
}

.register_row {
    background-color: #fff;
    z-index: 1;
    position: relative;
    box-shadow: 0px 9px 45px 0px rgba(100, 149, 191, 0.15);
    border: 4px solid #f48847;
    border-radius: 10px;
    padding: 15px 0;
}

.width_register {
    max-width: 700px;
    margin: 0px auto;
    padding: 100px 0;
}

.password_err {
    text-align: center;
    font-size: 24px;
    background-color: #ff000026;
    color: red;
    padding: 8px 0px;
    margin-bottom: 15px;
    border-radius: 9px;
    font-weight: 600;
}


@media (max-width: 768px) {
    .width_register {
        margin: 30px auto;
        max-width: initial;
    }

    .main_register_form:before {
        background-color: #e57424;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        width: 50%;
    }

    .inner_register_form {
        padding: 40px 10px;
    }
}